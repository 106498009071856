//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Avatar from '@/components/Avatar.vue'
export default {
  name: "ProfileNamestrip",
  props: [
    'id',
    'name', 
    'avatar',
    'nickname',
    'isOwner'
  ],
  components: {
    Avatar
  },
}
